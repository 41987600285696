@media screen and (max-width: 1090px) {
    #Team_box_title {
        font-size: 26px;
        padding-right: 90px;
        width: 480px;
    }
    #Team_box_text {
        padding-right: 90px;
        width: 480px;
        font-size: 18px;
    }
}

@media screen and (max-width: 980px) {
    #Team_box_title {
        padding-right: 60px;
        width: 420px;
    }
    #Team_box_text {
        padding-right: 60px;
        width: 420px;
    }
}

@media screen and (max-width: 897px) {
    #Team_body {
        justify-content: center;
        height: auto;
        background-image: none;
        background-position-x: 0;
        flex-direction: column-reverse;
        background-color: #FFC700;
    }
    #Team_box_left {
        display: flex;
    }
    #Team_box_right {
        padding-top: 54px;
        width: 100%;
        height: auto;
        background-image: none;
        align-items: center;
    }
    #Team_box_title {
        padding-left: 120px;
        padding-right: 120px;
        width: auto;
        height: 80px;
        font-size: 30px;
    }
    #Team_box_text {
        font-size: 20px;
        padding-bottom: 30px;
        padding-left: 120px;
        padding-right: 120px;
        width: auto;
    }
    #Team_box_2_bone {
        display: flex;
    }
}

@media screen and (max-width: 630px) {
    #Team_box_title {
        font-size: 20px;
        padding-left: 90px;
        padding-right: 90px;
        height: 60px;
    }
    #Team_box_text {
        font-size: 16px;
        padding-left: 90px;
        padding-right: 90px;
    }
    #Team_box_photo_1,
    #Team_box_photo_2 {
        margin: 0 30px;
    }
}

@media screen and (max-width: 520px) {
    #Team_box_title {
        padding-left: 70px;
        padding-right: 70px;
        height: 60px;
    }
    #Team_box_text {
        font-size: 15px;
        padding-left: 70px;
        padding-right: 70px;
    }
    #Team_box_photo_1,
    #Team_box_photo_2 {
        margin: 0 15px;
    }
}

@media screen and (max-width: 390px) {
    #Team_box_title {
        padding-left: 55px;
        padding-right: 55px;
        height: 60px;
    }
    #Team_box_text {
        padding-left: 55px;
        padding-right: 55px;
    }
    #Team_box_left_photo {
        width: 390px;
        height: 436px;
        /* background-image: url("../../image/Teams/box3.png"); */
    }
    #Team_box_2_bone {
        flex-wrap: wrap;
        justify-content: center;
    }
    #Team_box_photo_1 {
        margin: 15px;
    }
    #Team_box_photo_2 {
        margin: 15px;
    }
}