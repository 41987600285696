#Bottom_bone {
    z-index: 2;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    /* background: #595959; */
}

#Bottom_body {
    width: 1920px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex: none;
    order: 10;
    align-self: stretch;
    flex-grow: 0;
    justify-content: center;
    padding-top: 32px;
}

#Bottom_columes {
    width: 150px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    color: white;
}

#Bottom_columes , .just_text{
  width: 271px;
  font-size: 16px !important;
}

#Bottom_right {
    margin-left: 15px;
    width: 66px;
    height: 36px;
    background-image: url("../image/logo/logo.png");
}

#Bottom_columes #col_seperater{
    width: 100.5px;
    height: 0px;
    border: 1px solid #FFFFFF;
    border-radius: 15px;

    margin-top : 18px;
    margin-bottom: 18px;
}

#Bottom_columes a:link {
    text-decoration: none;
    color: #ffffff;
  }
  
#Bottom_columes a:visited {
    text-decoration: none;
    color: #ffffff;
  }
  
#Bottom_columes   a:hover {
    text-decoration: underline;
    color: #ffffff;
  }
  
#Bottom_columes a:active {
    text-decoration: underline;
    color: #ffffff;
  }

.Botton_stick{
  position: fixed;
  bottom: 0;
}