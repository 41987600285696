@media screen and (max-width: 1235px) {
    #Tapbar_body {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    #tap_button_bone {
        margin-right: 0;
        justify-content: center;
    }
    #cpy_icon {
        margin-left: 0;
    }
    .tap_button,
    .tap_button2 {
        margin: 10px 20px;
    }
    #tap_language {
        margin-top: 0;
    }
    #tap_language_close {
        margin-top: 10px;
    }
}

@media screen and (max-width: 980px) {
    #cpy_icon {
        margin-left: 19px;
        width: 255px;
        height: 45px;
    }
    #Tapbar_body {
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between
    }
    #tap_mobile_menu {
        display: flex;
        margin-top: 19px;
        margin-left: 0px;
        margin-right: 24px;
    }
    #tap_button_bone {
        position: fixed;
        top: 8px;
        right: 0;
    }
    .tap_button {
        display: none;
    }
    #tap_language {
        position: relative;
        margin-top: 15px;
        margin-right: 18px;
        margin-bottom: 0px;
        display: flex;
        width: 24px;
        height: 24px;
    }
}