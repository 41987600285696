body {
    background:  #262626 url(/src/image/01_main/bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: center;
    margin: 0;
    height: 100%;
    width: 100%;
    font-family: "NotoSans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    color: #262626;
}

.absolute {
    position: absolute;
}

.img_config {
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

@font-face {
    font-family: "jf-openhunin-1";
    src: url("./font/jf-openhuninn-1.1.ttf") format("opentype");
}

@font-face {
    font-family: "NotoSans";
    src: url("./font/NotoSansTC-Regular.otf") format("opentype");
}