#Privacy_main {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

#Privacy_bone {
    width: 100%;
    height: 94%;
    display: flex;
    justify-content: center;
}

#Privacy_body {
    position: relative;
    width: 1440px;
    height: 91%;
    overflow: auto;
    /* flex-wrap: wrap; */
    /* align-content: flex-start; */
    margin: 50px 20px;
    background-color: #80808033;
    border-radius: 3%;
}

#Privacy_text {
    color: black;
    font-size: 24px;
    padding: 5%;
    overflow: auto;
    height: 100%;
}