@media screen and (max-width: 436px) {
    #Bottom_left {
        line-height: 41px;
        font-size: 10px;
        text-align: center;
    }
    #Bottom_body {
        /* height: auto; */
    }
    #Bottom_right {
        width: 36px;
        height: 20px;
    }
}