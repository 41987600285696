@media screen and (max-width: 1180px) {
    #Partner_title {
        padding-top: 50px;
    }
    .Partner_photo {
        width: 150px;
        height: 150px;
    }
    #Partner_box_bone {
        width: 1000px;
    }
}

@media screen and (max-width: 980px) {
    .Partner_photo {
        width: 127px;
        height: 127px;
    }
    #Partner_box_bone {
        width: 500px;
    }
    #Partner_title {
        font-size: 20px;
        padding-top: 55px;
        padding-bottom: 80px;
    }
    #Partner_box {
        margin: 0;
        padding-top: 0px;
        padding-bottom: 60px;
    }
    #Partner_photo_3 {
        /* background-image: url("../../image/Partner/Bandai_Namco_logo.png"); */
    }
    #Partner_photo_4 {
        /* background-image: url("../../image/Partner/cybird.png"); */
    }
    #Partner_photo_5 {
        width: 171px;
        height: 171px;
    }
}