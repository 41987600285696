#main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

#content_bone{
    width: 100%;
    height: 100%;
    display: flex;
    /* color: black; */
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
}


#content_company_details{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1px;
    position: relative;
    width: 100%;
    border-radius: 15px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #312D2E;
}

#content_company_details > #_p{
    color: #312D2E;
}

#content_company_details > #com_logo{
    background-image: url(../image/logo/logo.png);
    background-repeat: no-repeat;
    background-position: center;
      background-size: contain;
      width: 100%;
      height: 50px;
  }

#content_company_details > #img_text_info{
    width: 100%;
    height: 100px;
    margin-bottom: 50px;
    margin-top: 10px;
    background-image: url("../image/01_main/title_intro.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

#content_company_details > a{
    width: 100%;
}

#content_company_details > a > #btn_contact_email{
    width: 100%;
    height: 50px;
    background-image: url("../image/01_main/btn_contactUs.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}



#_img{
    width: 414px;
    height: 315px;
    
    flex: none;
    order: 1;
    flex-grow: 0;
}

.cont_seperating{
    width: 100.5px;
    height: 0px;
    border: 1px solid #737373;
    border-radius: 15px;

    margin-top : 40px;
    margin-bottom: 40px;
}

.cont_seperating1 {
    width: 70%;
    margin-right: 15%;
    margin-left: 15%;
    margin-top : 30px;
    border: 1px solid #ED1B25;
    border-radius: 15px;
}


#overlay_bone{
    width: 1210px;
    height: 400px;
}

#overlay_bone #content_bound{
    position: relative;
    width: 560px;
    height: 560px;
    left: 697.72px;
    top: -50px;
    
    
    color: #FFFFFF;
}

#content_bound #_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
    gap: 25px;
    position: relative;
    width: 560px;
    height: 530px;
    

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
}

#_content h1{
    margin-block-end: 5px;
}

#_content #subcont{
    font-family: 'NotoSans';
    font-weight: 700;
    line-height: 30px;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
    color: #FFFFFF;
}



/* =-=-=-=-=  content_short_products =-=-=-=-= */

#content_prod_short_list{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 40px;
    width: 1106px;
    height: 542px;
    padding-right: 60px;
    margin-bottom: 100px;
}

#content_prod_short_list h1{
    font-family: 'Noto Serif TC';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    margin-block: 0;
}

#content_prod_short_list h1 .cont_seperating{
    margin-bottom: 0;
    margin-top: 30px;
}

#content_prod_short_list #_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
}

#_list ._list_item{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    width: auto;
    height: 120px;
    filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.2));
    border-radius: 5px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-right: 20px;
    margin-bottom: 30px;
}

#_list > div:nth-child(5) >  ._list_item_img{
    
}

#_list > div:nth-child(1) >  ._list_item_img{
    
}

#_list > div:nth-child(6) >  ._list_item_img{
    
}

#_list > div:nth-child(4) >  ._list_item_img{
    
}

#_list > div:nth-child(3) >  ._list_item_img{
    
}

#_list > div:nth-child(2) >  ._list_item_img{
    
}

._list_item > ._list_item_img{
    width: 120px;
    height: 100%;

    /* Inside auto layout */
    background-color: #adadad;

    flex: none;
    order: 0;
    flex-grow: 0;
}

._list_item > ._list_item_name{
    width: 241px;
    height: 100%;
    left: 120px;
    top: 0px;

    background: #FFFFFF;

    font-family: 'NotoSans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

/* =-=-=-=-=  content_partner_short_list =-=-=-=-= */

#content_partner_short_list{
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 40px;
    width: 1106px;
    height: 542px;
    padding-right: 60px;
    margin-bottom: 100px;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: center;
}

#content_partner_short_list h1{
    font-family: 'Noto Serif TC';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    margin-block: 0;
}

#content_partner_short_list h1 .cont_seperating{
    margin-bottom: 0;
    margin-top: 30px;
    margin-left: 14%;
}

