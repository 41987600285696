@media screen and (max-width: 1439px) {
    #Carousel_box {
        height: 781px;
    }
    #Carousel_body {
        align-items: center;
    }
    .Carousel_photo {
        height: 781px;
    }
    #Carousel_test {
        margin-top: -470px;
        margin-bottom: 350px;
        width: 100vw;
    }
}

@media screen and (max-width: 600px) {
    .Carousel_click {
        width: 51px;
        height: 68px;
    }
    .Carousel_c_bg {
        width: 51px;
        height: 68px;
    }
    .Carousel_c_line {
        height: 30px;
        margin-left: 20px;
    }
    .Carousel_c_line_1 {
        margin-top: 8px;
    }
    .Carousel_c_line_2 {
        margin-top: 29px;
    }
}

@media screen and (max-width: 436px) {
    #Carousel_title {
        font-size: 20px;
        height: 88px;
        line-height: 88px;
    }
}