@media screen and (max-width: 1465px) {
    #About_left_bone {
        padding: 40px 110px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-position-y: 40%;
        background-position-x: center;
        background-repeat: no-repeat;
        background-size: auto 180%;
    }
    #About_right_bone {
        padding: 40px 110px;
    }
    #About_title {
        width: 650px;
    }
    #About_text {
        margin-left: 12px;
        width: 520px;
    }
}

@media screen and (max-width: 720px) {
    #About_title {
        width: 350px;
        font-size: 24px;
    }
    #About_text {
        margin-left: 0;
        width: 350px;
        font-size: 16px;
    }
    #About_right_bone {
        width: 395px;
    }
    .About_item_bone {
        margin: 15px 10px;
        width: 105px;
        height: 77px;
    }
    .About_item_photo {
        background-size: 52px 52px;
        height: 52px;
    }
    .About_item_text {
        font-size: 13px;
    }
    #About_button {
        width: auto;
        height: 45px;
        border-radius: 10px;
    }
    #About_email {
        width: 27px;
        height: 19px;
        margin-left: 30px;
        margin-right: 14px;
    }
    #About_button_text {
        padding-right: 30px;
        font-size: 20px;
        line-height: 45px;
    }
    #About_button:active {
        box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.25);
    }
    #About_button:hover #About_email {
        background-image: url("../../image/01_main/btn_contactUs.png");
        width: 27px;
        height: 24px;
    }
}

@media screen and (max-width: 390px) {
    #About_title {
        width: 300px;
    }
    #About_text {
        margin-left: 0;
        width: 280px;
    }
    #About_right_bone {
        width: 330px;
    }
    .About_item_bone {
        margin: 15px 5px;
        width: 90px;
        height: 77px;
    }
}

@media screen and (max-width: 360px) {
    #About_body {
        width: 100%;
    }
    #About_left_bone {
        height: auto;
        padding: 40px 40px;
    }
    #About_right_bone {
        height: auto;
        padding: 40px 40px;
    }
    #About_title {
        width: auto;
        height: auto;
        font-size: 24px;
    }
    #About_text {
        margin-left: 0;
        width: auto;
        height: auto;
        font-size: 16px;
    }
}