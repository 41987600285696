@media screen and (max-width: 720px) {
    #Banner_body {
        background-position-x: 23%;
    }
}

@media screen and (max-width: 390px) {
    #Banner_bone {
        height: 500px;
    }
    #Banner_body {
        height: 500px;
        background-size: 390px 725px;
        /* background-image: url("../../image/banner_phone.png"); */
        background-position: center;
    }
}