
@media screen and (max-width: 1465px) {
    #content_company_details > #img_text_info{
        width: 349px;
    }
}

@media screen and (max-width: 720px) {

    #content_company_details > #img_text_info{
        width: 100%;
    }
}
